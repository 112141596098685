<template>
	<div>
		<bg-field
			:label="fieldLabel"
			:error="fieldError"
			:message="fieldMessage"
			class="mb-0"
		>
			<bg-input
				v-bind="$attrs"
				v-model="localValue"
				data-testid="input-currency-masking"
				@input="handleInput"
			/>
		</bg-field>
	</div>
</template>

<script>
import { currencyFormatter } from 'Utils/formatter';
import { BgInput, BgField } from 'bangul-vue';

export default {
	name: 'InputCurrencyMasking',
	components: {
		BgInput,
		BgField
	},
	inheritAttrs: false,

	props: {
		fieldLabel: {
			type: String,
			default: ''
		},
		fieldError: {
			type: Boolean,
			default: false
		},
		fieldMessage: {
			type: String,
			default: ''
		},
		timer: {
			type: Number,
			default: 1000
		},
		value: {
			type: [String, Number],
			default: null
		},
		maxChar: {
			type: [String, Number],
			default: null
		},
		isAllowZero: {
			type: Boolean,
			default: false,
		}
	},

	data() {
		return {
			timeout: null,
			localValue: null
		}
	},

	beforeMount() {
		this.manualTrigger();
	},

	methods: {
		inputMoneyAmount(value) {
			return currencyFormatter(value.toString());
		},

		handleInput(e) {
			const inputString = String(e);
			const rawInput = inputString.split('.').join('');
			let val = e;
			clearTimeout(this.timeout);

			if (this.maxChar) {
				if (rawInput.length > this.maxChar) {
					val = rawInput.slice(0, this.maxChar);
				}
			}

			this.$emit('input', this.localValue);

			this.localValue = val.toString().replace(/[^\d.]/g, '');
			this.timeout = setTimeout(() => {
				const value = val.toString().replace(/\D/g, '');

				const temp = value;
				this.localValue = this.inputMoneyAmount(temp);
				this.$emit('input', this.localValue);
			}, this.timer);
		},

		manualTrigger() {
			const nullChecker = 
				this.isAllowZero && this.value === 0 ? this.value : (this.value || '');
			this.localValue = this.inputMoneyAmount(nullChecker);
		}
	}

}
</script>